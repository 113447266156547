// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /**
    /** primary 
    --ion-color-primary: #0cd1e8;
    --ion-color-primary-rgb: 12, 209, 232;
    --ion-color-primary-contrast: #fffffc;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #0bb8cc;
    --ion-color-primary-tint: #24d6ea;
  
    /** secondary 
    --ion-color-secondary: #ffc200;
    --ion-color-secondary-rgb: 255, 194, 0;
    --ion-color-secondary-contrast: #fffffc;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #e6af00;
    --ion-color-secondary-tint: #ffda66;
    **/

  /** primary **/
  --ion-color-primary: #00a9bf;
  --ion-color-primary-rgb: 255, 194, 0;
  --ion-color-primary-contrast: #fffffc;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #00a9bf;
  --ion-color-primary-tint: #0d96a8;

  /** secondary * */
  --ion-color-secondary: #ffbe00;
  --ion-color-secondary-rgb: 12, 209, 232;
  --ion-color-secondary-contrast: #fffffc;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #ffbe00;
  --ion-color-secondary-tint: #ffbe00;

  /** tertiary **/
  --ion-color-tertiary: #7044ff;
  --ion-color-tertiary-rgb: 112, 68, 255;
  --ion-color-tertiary-contrast: #fffffc;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #633ce0;
  --ion-color-tertiary-tint: #7e57ff;

  /** success **/
  --ion-color-success: #77b25f;
  --ion-color-success-rgb: 206, 249, 198;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #77b25f;
  --ion-color-success-tint: #77b25f;

  /** warning **/
  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #fffffc;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  /** danger **/
  --ion-color-danger: #C64D28;
  --ion-color-danger-rgb: 254, 156, 135;
  --ion-color-danger-contrast: #000000;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #C64D28;
  --ion-color-danger-tint: #C64D28;

  /** dark **/
  --ion-color-dark: #2a303b;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #fffffc;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #2a303b;
  --ion-color-dark-tint: #2a303b;

  /** medium **/
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #fffffc;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  /** light **/
  --ion-color-light: #fffffc;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #e6e6e3;
  --ion-color-light-tint: #fffffd;

  --ion-background-color: #fffffc;
  --ion-background-color-rgb: 255, 255, 255;

  --ion-textinputbg: #eaeaea;

  --ion-overlay-background-color: ffffc;

  $button-md-text-color: #000000;
  $button-ios-text-color: #000000;
  $text: #3b3c40;
  ion-card {
    background: #fffffc !important;
  }
  ion-item {
    background: #fffffc00 !important;
  }
  .item-native {
    background: #fffffc !important;
  }
  .picker-wrapper {
    background: #fffffc !important;
  }
}

ion-item {
  background: #fffffc00 !important;
  --ion-item-background: #fffffc00 !important;
}
.item-native {
  background: #fffffc !important;
}
ion-header {
  background: #f1f7ee !important;
  --ion-item-background: #f1f7ee !important;
}
ion-toolbar {
  background: #ffbe00 !important;
  --ion-item-background: #fffbe00 !important;
  --ion-background-color: #ffbe00 !important;
}

.tablebox {
  background-color: ffffc;
  padding: 5px;
  margin: 8px 8px 8px 8px;
  border-radius: 8px;
  text-align: center;
  overflow-x: auto !important;
}
item-list {
  background-color: #fffffc !important;
  --ion-item-background: #fffffc !important;
  --ion-background-color: #fffffc !important;
}

item-list-header {
  background-color: #fffffc !important;
  --ion-item-background: #fffffc !important;
  --ion-background-color: #fffffc !important;
}
.new-content-box {
  padding-top: 100px;
}
.new-content-box-profile {
  padding-top: 163px;
}
.box {
  padding-left: 5px;
  padding-right: 5px;
  margin: 8px;
  margin: auto;
  border-radius: 8px;
  text-align: center;
  overflow-x: auto !important;
  @media only screen and (min-width: 768px) {
    
    margin: auto;
  }
}

.redhighlightbox {
  //background-color: var(--ion-color-light-shade) !important;
  border: var(--ion-color-primary) 1px solid;
  padding: 10px;
  margin: 10px;
  border-radius: 8px;
  text-align: center;
}

// ion-button {
//   //width: 30em;
//   min-width: 200px;
//   --box-shadow: none;
// }

ion-list {
  --ion-background-color: #fffffc;
}

ion-alert {
  --background: #fffffc !important;
}

.myteamhead {
  height: 100px;
  background-color: #fffffc !important;
}
.myteamheadh1 {
  font-weight: 900;
  padding: 20px;
}

.tabsmenu {
  color: var(--ion-color-dark);
  padding-left: 20px;
  span {
    margin-right: 20px;
  }
  .active {
    color: black;
    font-weight: 600;
    border-bottom: 3px solid;
    border-color: var(--ion-color-primary) !important;
  }
}

.dividerline {
  height: 1px;
  width: 100%;
  margin: 1px;
  background-color: var(--ion-color-primary);
}
/*
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 800 !important;
}
*/
.myteaminput {
  --border-width: 0 0 0 0;
  --highlight-color-focused: none !important;
  --border-width: 0 0 0 0 !important;
  --highlight-height: 0;
  --background-activated: #e1e1e100;
  --background-focused: #e1e1e100;
  --background-hover: #e1e1e100;
  .spacingdiv {
    height: 5px;
  }

  ion-label {
    --padding-bottom: 5px !important;
    --highlight-color-focused: none;
  }

  .fakeinput {
    padding: 5px;
    --padding-start: 10px !important;
    --padding-end: 10px;
    background-color: var(--ion-textinputbg);
    --highlight-color-focused: none;
    border-radius: 8px;
  }
  /*
  ion-input {
    padding: 5px;
    --padding-start: 10px !important;
    --padding-end: 10px;
    background-color: var(--ion-textinputbg);
    --highlight-color-focused: none;
    border-radius: 8px;
  }*/

  .item-highlight {
    display: none;
  }
}
/*$menu-width: 290px;


split-pane-md.split-pane-visible > .split-pane-side {
  min-width: 200px !important;
  max-width: 200px !important;
  border-right: var(--border);
  border-left: 0;
}*/
.menu-pane-visible {
  // max-width: 200px !important;
}

.form {
  ion-list-header {
    background-color: #fffffc;
  }

  .text-center {
    text-align: center;
  }

  .text-left {
    text-align: left;
  }

  .placeholder-profile {
    color: #cccccc;
  }

  .dob-label {
    color: #000000;
    padding: 10px;
    max-width: 50%;
  }

  .spacingdiv {
    height: 5px;
  }

  ion-label {
    --padding-bottom: 5px !important;
    --highlight-color-focused: none;
  }

  .fakeinput {
    padding: 5px;
    --padding-start: 10px !important;
    --padding-end: 10px;
    background-color: var(--ion-textinputbg);
    --highlight-color-focused: none;
    border-radius: 8px;
  }
  /*
  ion-input {
    padding: 5px;
    --padding-start: 10px !important;
    --padding-end: 10px;
    background-color: var(--ion-textinputbg);
    --highlight-color-focused: none;
    border-radius: 8px;
  }*/

  ion-item {
    --highlight-color-focused: none;
    --border-width: 0 0 0 0 !important;
    --highlight-height: 0;
  }
  .item-highlight {
    display: none;
  }
}

ion-list-header {
  background-color: #fffffc;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.placeholder-profile {
  color: #cccccc;
}

.dob-label {
  color: #000000;
  padding: 10px;
  max-width: 50%;
}

.spacingdiv {
  height: 5px;
}

ion-label {
  --padding-bottom: 5px !important;
  --highlight-color-focused: none;
}

.fakeinput {
  padding: 5px;
  --padding-start: 10px !important;
  --padding-end: 10px;
  background-color: var(--ion-textinputbg);
  --highlight-color-focused: none;
  border-radius: 8px;
}

ion-item {
  --highlight-color-focused: none;
  --border-width: 0 0 0 0 !important;
}
.item-highlight {
  display: none;
}

ion-content {
  padding: 20px !important;
 }

.card-price:before {
  content: "";
  position: absolute;
  display: block;
  left: -19px;
  width: 0;
  height: 0;
  border-top: 19px solid transparent;
  border-bottom: 19px solid transparent;
  border-right: 19px solid #6ab070;
}
.card-price {
  transform: rotate(10deg) scale(0.8);
  display: inline-block;

  width: auto;
  height: 38px;

  background-color: #6ab070;
  -webkit-border-radius: 3px 4px 4px 3px;
  -moz-border-radius: 3px 4px 4px 3px;
  border-radius: 3px 4px 4px 3px;

  border-left: 1px solid #6ab070;

  /* This makes room for the triangle */
  margin-left: 19px;

  position: relative;

  color: ffffc;
  font-weight: 300;
  font-size: 22px;
  line-height: 38px;

  padding: 0 10px 0 10px;
}
/* Makes the circle */
.card-price:after {
  content: "";
  background-color: ffffc;
  border-radius: 50%;
  width: 4px;
  height: 4px;
  display: block;
  position: absolute;
  left: -9px;
  top: 17px;
}

.appear {
  animation-name: appearanim;
  animation-duration: 0.7s;
  animation-timing-function: ease-in-out;
  opacity: 0;
  animation-fill-mode: forwards;
}

@keyframes appearanim {
  0% {
    margin-top: 100px;
    opacity: 0;
  }

  100% {
    margin-top: 0;
    opacity: 1;
  }
}

.appearOpacity {
  animation-name: appearanimOpacity;
  animation-duration: 0.7s;
  animation-timing-function: ease-in-out;
  opacity: 0;
  animation-fill-mode: forwards;
}

@keyframes appearanimOpacity {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
.delay1 {
  animation-delay: 100ms;
}
.delay2 {
  animation-delay: 200ms;
}
.delay3 {
  animation-delay: 300ms;
}
.delay4 {
  animation-delay: 400ms;
}
.delay5 {
  animation-delay: 500ms;
}

.delay6 {
  animation-delay: 600ms;
}

.delay7 {
  animation-delay: 700ms;
}
.fc-right {
  text-align: right;
  width: min-content;
}
#more-arrows {
  width: 75px;
  height: 65px;
  position: absolute;
  transform: rotate(270deg) translateX(0%);
  right: 0px;
  top: 13%;

  polygon {
    fill: #fffffc;
    transition: all 0.2s ease-out;
      &.arrow-middle {
      animation-name: arrowani2;
      animation-duration: 0.7s;
      animation-timing-function: ease-in-out;
      animation-fill-mode: forwards;
      animation-delay: 1s;
    }
    &.arrow-top {
      animation-name: arrowani1;
      animation-duration: 0.7s;
      animation-timing-function: ease-in-out;
      animation-fill-mode: forwards;
      animation-delay: 1s;
    }
  }
}

@keyframes arrowani1 {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(25px);
  }
}
@keyframes arrowani2 {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(10px);
  }
}

polygon {
  fill: #fffffc;
  transition: all 0.2s ease-out;

  &.arrow-middle {
    opacity: 0.4;
  }

  &.arrow-top {
    opacity: 0.25;
  }

  &.arrow-bottom {
    opacity: 0.75
  }
}

.card-price:before {
  content: "";
  position: absolute;
  display: block;
  left: -19px;
  width: 0;
  height: 0;
  border-top: 19px solid transparent;
  border-bottom: 19px solid transparent;
  border-right: 19px solid #6ab070;
}
.card-price {
  transform: rotate(10deg) scale(0.8);
  display: inline-block;

  width: auto;
  height: 38px;

  background-color: #6ab070;
  -webkit-border-radius: 3px 4px 4px 3px;
  -moz-border-radius: 3px 4px 4px 3px;
  border-radius: 3px 4px 4px 3px;

  border-left: 1px solid #6ab070;

  /* This makes room for the triangle */
  margin-left: 19px;

  position: relative;

  color: #fffffc;
  font-weight: 300;
  font-size: 22px;
  line-height: 38px;

  padding: 0 10px 0 10px;
}
/* Makes the circle */
.card-price:after {
  content: "";
  background-color: #fffffc;
  border-radius: 50%;
  width: 4px;
  height: 4px;
  display: block;
  position: absolute;
  left: -9px;
  top: 17px;
}

.basicbg {
  background: var(--ion-color-light-shade);

  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.pendingbg {
  background: var(--ion-color-primary);
}
.acceptedbg {
  background: var(--ion-color-primary);
}
.declinedbg {
  background: var(--ion-color-primary);
}
.finallyacceptedbg {
  background: var(--ion-color-primary);
}
.finallyacceptedbgheader {
  background-color: #0083b0;
}
.smallstatus {
  margin: 0 0 -15px -15px;
  font-size: smaller;
  background: wheat;
  width: fit-content;
  padding: 2px;
  border-radius: 7px;
}
.sedcardflavour {
  max-width: 500px;
  margin: auto;
  margin-bottom: 5px;
  ion-card-header {
    background-color: #ffbe00
    ;
    text-align: left;
    color: #3b3c40;
  }

  ion-card-content {
    padding-bottom: 0px;

    background: var(--ion-color-light);

    text-align: left;
    color: #3b3c40;
  }
}

/* box ------------------------------------------------------ */
@font-face {
  font-family: "Bungee";
  src: url("../assets/Bungee-Regular.ttf");
  font-weight: 200;
  font-style: normal;
}

#logobox {
  font-family: "Bungee", sans-serif;
  text-align: center;
  font-size: 2em;
  font-weight: bold;
  -webkit-backface-visibility: hidden; /* fixes flashing */
}

/* flashlight ------------------------------------------------------ */

#flashlight {
  color: rgba(255, 194, 0, 1);
  perspective: 80px;
  outline: none;
}

/* flash ------------------------------------------------------ */

#flash {
  display: inline-block;
  text-shadow: rgb(180, 138, 1) 0 0 0px, rgb(180, 138, 1) 0 -1px 0px,
    rgb(180, 138, 1) 0 -3px 0px, rgb(255, 196, 0) 0 2px 0px;

  transition: all 0.3s cubic-bezier(0, 1, 0, 1);
  animation-name: logoanimate;
  animation-duration: 0.3s;
  animation-delay: 1s;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
}

/*#box:hover #flash {
  text-shadow: #111 0 0 1px, rgba(255, 255, 252, 0.1) 0 1px 3px;
  margin-left: 20px;
  transition: margin-left 1s cubic-bezier(0, 0.75, 0, 1);
}*/

@keyframes logoanimate {
  from {
    text-shadow: rgb(180, 138, 1) 0 0 0px, rgb(180, 138, 1) 0 -1px 0px,
      rgb(180, 138, 1) 0 -3px 0px, rgb(255, 196, 0) 0 2px 0px;
  }
  to {
    text-shadow: rgb(180, 138, 1) 0 0 0px, rgb(180, 138, 1) 0 0px 0px,
      rgb(180, 138, 1) 0 0px 0px, rgb(255, 196, 0) 0 0px 0px;
    margin-left: 20px;
  }
}

@font-face {
  font-family: "Baskerville";
  src: url("../assets/LibreBaskerville-Regular.ttf");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("../assets/Inter-ExtraLight.ttf");
  font-weight: 400;
  font-style: normal;
}
.ml5 {
  position: relative;
  font-weight: normal;
  font-size: 2em;
  color: #ffc200;
}

.ml5 .text-wrapper {
  position: relative;
  display: inline-block;
  padding-top: 0.1em;
  padding-right: 0.05em;
  padding-bottom: 0.15em;
  line-height: 1em;
}

.ml5 .line {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 3px;
  width: 100%;
  background-color: #ffc200;
  transform-origin: 0.5 0;
}

.ml5 .ampersand {
  font-family: "Baskerville", serif !important;
  font-weight: 400;
  width: 1em;
  margin-right: -0.1em;
  margin-left: -0.1em;
}

.ml5 .letters {
  display: inline-block;
  opacity: 0;
}
.ml5 .letters .letters-right {
  font-family: Inter, sans-serif !important;
}
.ml5 .letters .letters-left {
  font-family: Inter, sans-serif !important;
}
@font-face {
  font-family: "OpenSans";
  font-style: normal;
  font-weight: normal;
  font-family: "OpenSans";
  src: url("./../assets/font/OpenSans-Regular.ttf");
}
$font-family-base: "OpenSans";
:root {
  --ion-font-family: "OpenSans";
}
ion-button {
  --border-radius: 5px !important;
}
.verticalcenter {
  left: 50%;
  position: relative;
  transform: translate(-50%);
}
.nothingthere {
  text-align: center;

  /* ion-text {
    left: 50%;
    position: relative;
    transform: translate(-50%);
  }
  ion-icon {
    left: 50%;
    position: relative;
    transform: translate(-50%);
  }*/
}
.shakebase {
  font-size: 24px;
}

.shakeit {
  /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: shake 0.5s;

  /* When the animation is finished, start again */
  animation-iteration-count: infinite;
}

@keyframes shake {
  0% {
    transform: rotate(1deg);
  }

  50% {
    transform: rotate(-1deg);
  }

  100% {
    transform: rotate(1deg);
  }
}
.shakeit2 {
  /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: shake2 0.5s;

  /* When the animation is finished, start again */
  animation-iteration-count: infinite;
}

@keyframes shake2 {
  0% {
    transform: rotate(2deg);
  }

  50% {
    transform: rotate(-4deg);
  }

  100% {
    transform: rotate(2deg);
  }
}
.spinit {
  /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: spin 0.5s;

  /* When the animation is finished, start again */
  animation-iteration-count: infinite;
}

@keyframes spin {
  0% {
    transform: rotate(-180deg);
  }

  50% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(180deg);
  }
}
.primarybgcolor {
  background-color: var(--ion-color-primary);
  --ion-background-color: var(--ion-color-primary);
}
.lightbgcolor {
  background-color: var(--ion-color-light);
  --ion-background-color: var(--ion-color-light);
}
#texttyping {
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: 0.15em solid orange; /* The typwriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  letter-spacing: 0.15em; /* Adjust as needed */
  animation: typing 3.5s steps(40, end), blink-caret 0.75s step-end infinite;
}

/* The typing effect */
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: orange;
  }
}

.appearmig {
  position: absolute;
  margin: 0 auto;
  left: 0;
  right: 0;
  z-index: -1;
  width: 70px;
  animation-name: appearanimig;
  animation-duration: 2s;
  animation-delay: 1.3s;
  bottom: -100px;

  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}

@keyframes appearanimig {
  0% {
    bottom: -100px;
  }

  100% {
    bottom: 30px;
  }
}

.standardbutton {
  margin-top: 4px;
  position: relative;
  margin-bottom: 19px;
  transform: translateX(-50%);
  left: 50%;
  @media only screen and (min-width: 768px) {
    width: 50%;
  }
}
.text-align-left * {
  text-align: left;
  --text-align: left;
}
.limit-width{
  max-width: 500px;
  margin: auto;
}
