/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

.swiper-pagination {
  top: 0px !important;
}

.authformwidth {
  max-width: 500px;
  margin: auto;

}
@media (min-width: 992px) {
  .menu-width {
    width: 280px !important;
  }
}
@font-face {
  font-family: "LexendZetta";
  src: url("./assets/font/LexendZetta-VariableFont_wght.ttf");
}

@font-face {
  font-family: "LexendZettaThin";
  src: url("./assets/font/LexendZetta-VariableFont_wght1.ttf");
  font-weight: 100;
}

@font-face {
  font-family: "OpenSansCondensed";
  src: url("./assets/font/OpenSansCondensed-Bold.ttf");
}

@font-face {
  font-family: "OpenSansCondensedLight";
  src: url("./assets/font/OpenSansCondensed-Light.ttf");
}

@font-face {
  font-family: "OpenSans";
  src: url("./assets/font/OpenSans-Regular.ttf");
}

$font-family-base: "OpenSans";
:root {
  --ion-font-family: "OpenSans";
}

ion-button {
  font-family: "OpenSans";
  font-weight: bold;
  text-transform: uppercase;
  }

ion-card-title {
  color: #fffffc;
  ion-con {
    color: #fffffc;
  }
}

ion-title {
  font-family: LexendZetta;
  font-size: calc(12px + (19 - 13) * ((100vw - 300px) / (1600 - 300)));
  font-weight: 600 !important;
}

.ion-title-wrap {
  font-family: LexendZetta;
  font-size: calc(12px + (20 - 13) * ((100vw - 300px) / (1600 - 300)));
  flex: 1;
  white-space: normal;
  word-break: break-word;
  line-height: 1.2;
  hyphens: auto;
  overflow: visible;
  font-weight: 600;
  padding-left: 15px;
}

.center-div-ios {
  font-family: LexendZetta;
  font-size: calc(12px + (20 - 13) * ((100vw - 300px) / (1600 - 300)));
  flex: 1;
  white-space: normal;
  word-break: break-word;
  line-height: 1.2;
  hyphens: auto;
  overflow: visible;
  font-weight: 600;
  padding-left: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sortselect {
  background: var(--ion-color-secondary);
  color: var(--ion-color-light);
  box-shadow: none;
  text-transform: uppercase;
  font-size: 13px;
  padding: 7px;
}

.sortcard {
  margin: auto;
  margin-bottom: 16px;
  border-radius: 13px;
  max-width: 500px;
  box-shadow: none;
}

h6 {
  font-family: "OpenSans";
  font-size: 14pt;
  font-weight: 500;
}

h5 {
  font-family: "OpenSans";
  font-size: 12pt;
  font-weight: bold;
}

h4 {
  font-family: "OpenSans";
  font-size: 11pt;
  letter-spacing: 1.5px;
  font-weight: bold;
  text-transform: uppercase;
}
h3 {
  font-family: "OpenSansCondensed";
  font-size: 14pt;
  letter-spacing: 1.5px;
}

h2 {
  font-family: "OpenSansCondensedLight";
  font-size: 16pt;
}

h1 {
  font-family: "LexendZetta";
}

// @media screen and (max-width: 64em) {
//   h1 {
//     font-size: 20px;
//   }
// }
// @media screen and (max-width: 50em) {
//   h1 {
//     font-size: 18px;
//   }
// }
// @media screen and (max-width: 30em) {
//   h1 {
//     font-size: 16px;
//   }
// }



.pricetagpic {
  margin: auto;
  background-image: url("./assets/img/pricetag.svg");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: 83px;
  min-width: 83px;
  height: 28px;
  margin-right: 0;
  
}
.pricetagpic-dark {
  margin: auto;
  background-image: url("./assets/img/pricetag-dark.svg");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: 83px;
  min-width: 83px;
  height: 28px;
  margin-right: 0;
}
.blinking-cursor {
  color: var(--ion-color-primary);
  -webkit-animation: 1s blink step-end infinite;
  -moz-animation: 1s blink step-end infinite;
  -ms-animation: 1s blink step-end infinite;
  -o-animation: 1s blink step-end infinite;
  animation: 1s blink step-end infinite;
}

@keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: var(--ion-color-primary);
  }
}

@-moz-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: var(--ion-color-primary);
  }
}

@-webkit-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: var(--ion-color-primary);
  }
}

@-ms-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: var(--ion-color-primary);
  }
}

@-o-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: var(--ion-color-primary);
  }
}

.action-sheet-group {
  background: white;
  --background: white;
}
ion-card-title {
 font-size: 20px;
}
ion-content {
  --background: no-repeat right top url;
  background-color: #f1f7ee;
}
.topofthetop {
  z-index: 1000;
}
.chillload {
  .loading-content {
    text-align: center;
  }
  img {
    width: 40px;
    height: 50px;
  }
}


// Screen size hidden
.invisible {
  visibility: hidden !important;
}

.hidden-xs-up {
  display: none !important;
}

@media (max-width: 575px) {
  .hidden-xs-down {
    display: none !important;
  }
}

@media (min-width: 576px) {
  .hidden-sm-up {
    display: none !important;
  }
}

@media (max-width: 767px) {
  .hidden-sm-down {
    display: none !important;
  }
}

@media (min-width: 768px) {
  .hidden-md-up {
    display: none !important;
  }
}

@media (max-width: 991px) {
  .hidden-md-down {
    display: none !important;
  }
}

@media (min-width: 992px) {
  .hidden-lg-up {
    display: none !important;
  }
}

@media (max-width: 1199px) {
  .hidden-lg-down {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .hidden-xl-up {
    display: none !important;
  }
}

.hidden-xl-down {
  display: none !important;
}

.alert-radio-group::-webkit-scrollbar, .alert-checkbox-group::-webkit-scrollbar {
 width: 0.5em;
 display: block !important;
 }

 .alert-radio-group::-webkit-scrollbar-track, .alert-checkbox-group::-webkit-scrollbar-track {
   -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
   box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
 }

 .alert-radio-group::-webkit-scrollbar-thumb, .alert-checkbox-group::-webkit-scrollbar-thumb {
   background-color: darkgrey;
   outline: 1px solid slategrey;
 }

 .popover-big .alert-wrapper {
  width: 100%;
  }

.popover-content.sc-ion-popover-md {
  position: static !important;
  margin-left: 3px;
}
.sc-ion-popover-md-h {
  --width: calc(100% - 5px);
  --max-height: 80%;
}
.popover-content {
  --min-width: 250px;
}

 .alert-message::-webkit-scrollbar {
   width: 0.5em;
   display: block !important;
   }
  
 .alert-message::-webkit-scrollbar-track {
     -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
     box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
   }
  
 .alert-message::-webkit-scrollbar-thumb {
     background-color: darkgrey;
     outline: 1px solid slategrey;
   }

.item.sc-ion-label-md-h, .item .sc-ion-label-md-h {
    white-space: pre-line !important;
   }

   .sc-ion-alert-md-h {
     --width: calc(100% - 60px);
     --max-width: 500px;
   }

   .alert-message.sc-ion-alert-md, .alert-input-group.sc-ion-alert-md {
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 20px;
    padding-bottom: 0px !important;
    color: var(--ion-color-step-550, #737373);
}

.fc .fc-toolbar-title {
  font-size: 30px !important;
}

@media (max-width: 600px) {
  .fc .fc-toolbar.fc-header-toolbar {
    flex-flow: column;
  }
}

ion-toolbar {
  --background: #ffbe00;
  ion-menu-button {
    color: #000000;
  }
  ion-back-button {
    color: #000000;
  }
}

.fc-container a {
  color: #3b3c40
}
.native-input[type=date]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

.custPopover {
      --width: 300px;
      // --offset-x: none;
      ion-datetime {
        cursor: pointer;
      }
}

ion-accordion-group{
  ion-accordion {
      .ion-accordion-toggle-icon {
        color: var(--ion-color-dark);
      }
  }
}

//Used for ion-select Befristungsgrund (limitationReason) in sedcardnew-form.component
button.text-wrap {
    height: auto !important;
    contain: content !important;
}
button.text-wrap .alert-radio-label.sc-ion-alert-md {
    white-space: normal !important;
    font-size: small;
}

div.alert-wrapper.ion-overlay-wrapper.sc-ion-alert-md {
  width: fit-content !important;
}
//

section.textWidgetAnnotation {
  border-style: none !important;
}
section.textWidgetAnnotation input {
  cursor: text !important;
  border-style: none !important;
}

.sortcard {
  height: 30px;
}

.sortcard ion-card-header {
  height: 30px;
}

.sortcard ion-card-title {
  height: 30px;
  display: flex;            
  align-items: center;
}

.sortcard ion-select {
  height: 30px;
  display: flex;            
  align-items: center;
}

[hidden] {
  display: none !important;
}
